.Terminals {
  background: #fff;
}

.container {
  width: 82%;
  margin: 0 auto;
  padding: 80px 0;

  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 45px;

  h2 {
    color: #212121;
    font-family: "Factor A", sans-serif;
    font-size: 36px;
    font-style: normal;
    font-weight: 700;
    line-height: 110%;

    span {
      color: #F63939;
    }
  }
}

.swiperItem {
  display: flex;
  align-items: center;
  gap: 32px;

  img {
    height: 265px;
  }
}

.content {
  display: flex;
  flex-direction: column;
  gap: 24px;
  text-align: start;

  h3 {
    color: #212121;
    font-size: 20px;
    font-weight: 600;
    line-height: 120%;
  }

  p {
    color: #6C6E79;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
  }
}


@media (max-width: 768px) {
  .container {
    width: 90%;
    text-align: center;

    h2 {
      font-size: 32px;
    }
  }

  .swiperItem {
    flex-direction: column;
    min-width: 100%;
  }


}
