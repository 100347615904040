.Description {


  background: #fff;


}

.container {
  width: 82%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 80px 0;
}

.content {
  width: 50%;
  display: flex;
  flex-direction: column;
  gap: 24px;

  h2 {
    color: #212121;
    font-family: "Factor A", sans-serif;
    font-size: 36px;
    font-style: normal;
    font-weight: 700;
    line-height: 110%;

    span {
      color: #F63939;
    }
  }

  p {
    color: #6C6E79;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
  }
}

.image {
  width: 50%;

  img {
    max-height: 500px;
  }
}


@media (max-width: 1024px) {
  .content{
    width: 60%;
  }
  .image {
    width: 40%;
  }
}

@media (max-width: 768px) {
  .container {
    width: 90%;
    flex-direction: column;
    gap: 40px;
    padding: 40px 0;
  }

  .content{
    width: 100%;
    text-align: center;

    h2 {
      font-size: 32px;
    }
  }
  .image {
    width: 100%;
  }
}
