.CatalogBlock {
  width: 82%;
  padding: 64px 0;
  margin: 0 auto;

  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;
}

.title {
  color: #212121;
  font-family: 'Factor A', sans-serif;
  font-size: 36px;
  font-weight: 700;
  line-height: 110%;
  text-align: center;

  span {
    color: #F63939;
  }
}

.catalog {
  display: flex;
  gap: 24px;
  flex-wrap: wrap;
  justify-content: center;
}

.item {
  width: 276px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 4px;
  background: #fff;
  padding: 8px 16px;
  text-align: right;
  color: #212121;

  img {
    max-height: 56px;
  }
}

.link {
  padding: 12px;
  text-align: center;
  background-color: #F63939;
  color: #FFF;
  font-size: 16px;
  transition: all 0.4s ease-in-out 0s;

  &:hover {
    background-color: #D22E2EFF;
  }
}

@media (max-width: 768px) {
  .CatalogBlock {
    width: 90%;
  }
}
