.TopBlock {
  padding: 40px 0;
  margin: 0 auto;
  width: 82%;
}

.container {
  display: flex;
  justify-content: space-between;

  .left {
    width: 40%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 24px;
  }

  .right {
    padding: 0 40px;
    margin: auto;

    img {
      max-height: 375px;
    }
  }
}

.title {
  color: #212121;
  font-family: 'Factor A', sans-serif;
  font-size: 56px;
  font-weight: 700;
  line-height: 110%;

  span {
    color: #F63939;
  }
}

.text {
  color: #6C6E79;
  font-size: 16px;
  font-weight: 500;
  line-height: 150%
}


@media (max-width: 1024px) {
  .container {
    .left {
      width: 60%;
    }

    .right {
      padding: 0 20px;
    }
  }

  .title {
    font-size: 48px;
  }
}

@media (max-width: 768px) {
  .TopBlock {
    width: 90%;
  }

  .container {
    flex-direction: column;
    gap: 24px;

    .left {
      width: 100%;
      gap: 16px;
    }

    .right {
      width: 100%;
      display: flex;
      justify-content: center;
      padding: 0;
    }
  }


}
