@import "assets/styles/reset.scss";
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap');

@font-face {
  font-family: 'Factor A';
  src: url('./assets/fonts/FactorA-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

html {
  background: #EEE;
  scroll-behavior: smooth;
}

body {
  font-family: 'Inter', sans-serif;
  font-size: 14px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.swiper {
  width: 100%;
  height: 100%;
  padding-bottom: 4rem;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;

  display: block;
  flex-shrink: 0;
  height: 100%;
}

.swiper-slide img {
  display: block;
  object-fit: cover;
}

.swiper-wrapper {
  box-sizing: content-box;
  display: flex;
  height: 100%;
  position: relative;
  width: 100%;
  z-index: 1;
}

.swiper-pagination-bullet {
  background: #6c6e79;
  height: 5px;
  transition: all .35s ease;
  width: 5px;
}
.swiper-pagination-bullet-active {
  background: #f63939;
  border-radius: 10px;
  width: 14px;
}

.mySwiper {

}
