.Footer {
  background: #212121;
  color: #6C6E79;
  font-weight: 400;
  line-height: 20px;
}

.container {
  width: 82%;
  margin: 0 auto;
  padding: 48px 0;

  display: flex;
  justify-content: space-between;
  gap: 40px;
}

.logo {
  display: flex;
  flex-direction: column;
  gap: 32px;
  width: 200px;
}

.contacts, .license {
  display: flex;
  flex-direction: column;
  gap: 8px;
  flex: 1;

  a {
    color: #6C6E79;

    &:hover {
      color: #F63939;
    }
  }
}

.license {
}


@media (max-width: 900px) {
  .container {
    flex-direction: column;
  }
}

@media (max-width: 768px) {
  .container {
    width: 90%;
    gap: 32px;
  }
}
