.AboutUs {
  width: 82%;
  margin: 0 auto;
  padding: 80px 0;

  display: flex;
  flex-direction: column;
  gap: 45px;

  h2 {
    text-align: center;
    color: #212121;
    font-family: "Factor A", sans-serif;
    font-size: 36px;
    font-style: normal;
    font-weight: 700;
    line-height: 110%;

    span {
      color: #F63939;
    }
  }
}

.content {
  display: flex;
  gap: 40px;
}

.item {
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 50%;

  h3 {
    color: #212121;
    font-family: 'Factor A', sans-serif;
    font-size: 24px;
    font-weight: 700;
    line-height: 117%;
  }

  p {
    color: #6C6E79;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
  }
}

@media (max-width: 768px) {
  .AboutUs {
    width: 90%;
    gap: 32px;
    padding: 48px 0;
  }

  .content {
    flex-direction: column;
  }

  .item {
    width: 100%;

    h3 {
      font-size: 20px;
    }
  }
}
