.StatsBlock {
  background: #fff;
  padding: 40px 0;
}

.container {
  width: 82%;
  margin: 0 auto;
  display: flex;
  gap: 24px;
}

.pair {
  display: flex;
  gap: 24px;
  justify-content: space-between;
  flex: 1;
}

.item {
  flex: 1;
  color: #212121;

  h4 {
    font-family: 'Factor A', sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 117%;
  }

  p {
    font-weight: 400;
    line-height: 20px;
  }
}

@media (max-width: 1024px) {
  .container {
    flex-direction: column;
  }
}

@media (max-width: 768px) {
  .container {
    width: 90%;
  }
}
